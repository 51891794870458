$brand-colour: #a00;
$brand-font: "Avenir Next","Helvetica Neue",Arial,sans-serif;

html, body {
  background-color: #f6f6f6
}

main > section {
  padding: 40px;
  &:first-child { background-color: #fff; }
}

.skip-link {
  background: darken($brand-colour, 10%);
  font-family: $brand-font;
  color: #fff;
  font-weight: 700;
  left: .5rem;
  top: .5rem;
  padding: 4px;
  position: absolute;
  transform: translateY(-150%);
  border: 1px solid darken($brand-colour, 20%);
  transition: transform 0.3s;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.25);
  &:focus { transform: translateY(0%); }
  &:hover {
    color: #fff;
    background: darken($brand-colour, 5%);
  }
}

header, nav {
  text-align: center;
  background: $brand-colour;
  padding: 10px 0;
  a, a:hover, a:active {
    color: #fff;
    text-decoration: none;
    z-index: 2;
  }
  h1 {
    @media (max-width: 380px) {
      & { font-size: 2rem !important; }
    }
    @media (min-width: 381px) and (max-width: 520px) {
      & { font-size: 2.5rem !important; }
    }
  }
}
canvas#header_bg {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  z-index: 1;
}
header a { position: relative; }
nav {
  background: darken($brand-colour, 10%);
  font-family: $brand-font;
  letter-spacing: -0.025em;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: block;
    margin: 0 15px;
    &, &:hover { color: #fff; }
    &:active { color: #ccc; }
  }
}

article {
  padding: 40px 0;
  &:not(:last-child) { border-bottom: 1px solid #ddd; }
  h2 { margin-bottom: 0; }
  small {
    display: block;
    margin-top: 10px;
  }
}

article time,
section small#metadata {
  display: block;
  font-size: 0.8rem;
  color: #666;
  font-style: italic;
  margin-bottom: .5rem;
}
span.post-tag {
  padding: .1rem .3rem;
  border-radius: .2rem;
  background: #666;
  color: #fff;
  margin-left: .1rem;
  vertical-align: middle;
  font-weight: 500;
}

.brands {
  padding-bottom: 0;
  h2 { margin-bottom: 1.5rem; }
  div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    a:not(:last-child) {
      margin-right: 1.5rem;
      @media (max-width: 767px) {
        & { margin-right: 0; }
      }
    }
    img {
      height: 60px;
    }
  }
  .dsgentertainment img {
    -webkit-filter: drop-shadow( 0px 1px 1px rgba(0, 0, 0, .4));
    filter: drop-shadow( 0px 1px 1px rgba(0, 0, 0, .4));
  }
}

.phone {
  text-align: center;
  padding-bottom: 0;
  h4 {
    font-family: $brand-font;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin-bottom: 0;
  }
  a {
    display: block;
    font-size: 3em;
    color: $brand-colour;
    font-family: $brand-font;
    font-weight: bold;
    letter-spacing: -0.05em;
    &:hover, &:active {
      color: darken($brand-colour, 15%);
      text-decoration: none;
    }
  }
  small {
    display: block;
  }
}

h1, h2, h3, span.logo {
  font-family: $brand-font;
  font-weight: 400 !important;
  letter-spacing: -0.05em;
  sup {
    font-size: 0.3em;
    top: -1.5em;
    font-weight: 100;
    left: -0.25em;
  }
  footer & {
    font-weight: 500 !important;
    sup {
      font-size: 0.6em;
      top: -0.5em;
    }
  }
}
h1.brand_logo, h2.brand_logo, h3.brand_logo, span.brand_logo {
  strong { font-weight: 600; }
}

.leadinModal {
  h4, button {
    font-family: $brand-font;
    letter-spacing: -0.025em;
  }
  button {
    font-weight: 500 !important;
    font-size: 1.2em !important;
  }
}

div.footnotes {
  padding-top: 1rem;
  border-top: 1px solid #ccc;
  font-size: 0.85rem;
}

footer {
  font-size: 0.8em;
  color: #999;
  text-align: center;
  margin: 40px 0;
  a {
    color: #999;
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    & { background: url('/assets/dosomegood.svg') no-repeat right 15px center; }
    &:hover { background-image: url('/assets/dosomegood_hover.svg'); }
  }
}
